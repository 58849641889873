import React from "react";
// @ts-nocheck
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import Franqueado from "./pages/Franqueado";

const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/franqueado" element={<Franqueado />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
