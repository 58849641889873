import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;

  h3 {
    font-family: "avenir_nextbold" !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #000 !important;
  }
  h1 {
    //styleName: ExtraBigTittle;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: center;
    color: #000 !important;
  }
  small {
    //styleName: bodyTXT;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 24px;
    color: #000 !important;
  }
  @media screen and (max-width: 780px) {
    h3 {
      margin-top: 40px;
    }
  }
`;

export const Cont = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  @media screen and (max-width: 780px) {
    transition: 0.8s all ease;
    background: #fff;
  }
`;

export const DualBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const Box = styled.div`
  width: 624px;
  height: 511px;
  padding: 32px;
  border: 1px solid #000;
  gap: 32px;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */

  @media screen and (max-width: 780px) {
    margin: 0 10px 0 10px;
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1280px) {
    margin: 0 20px 10px 20px;
    width: 100%;
    height: auto;
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: 40px !important;
  p {
    color: #000 !important;
    text-align: center;
    font-family: "avenir_nextbold" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
  h5 {
    color: #000 !important;
    text-align: center;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }
  p:nth-child(3) {
    color: #000 !important;
    text-align: center;
    font-family: "avenir_nextregular" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150%; /* 27px */
  }
`;

export const BoxBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  p {
    color: #000;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
`;
