import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
  }
`;

export const WrapperBox = styled.div`
  display: flex;
  width: 768px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 112px;
  h5 {
    font-family: "avenir_nextregular" !important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    color: #000 !important;
  }
  h1 {
    //styleName: ExtraBigTittle;
    font-family: "avenir_nextbold" !important;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    color: #000 !important;
  }
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 80px;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 624px;
  border: 1px solid #000;
  padding: 32px;
  h1 {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  @media screen and (max-width: 1280px) {
    width: 600px !important;
  }
`;

export const WrapperStar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const WrapperPerson = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const BoxPerson = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }

  h5 {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
`;
