//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import motoHome from "../../../assets/img/moto-home.svg";
import "./styles.css";
import motoescola from "../../../assets/images/home/motoescola_img.png";
import motoescolamobile from "../../../assets/images/home/motoescolamobile@2x.png";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#FFF",
    // marginTop: "80px !important",
    padding: "15px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "0px !important",
      justifyContent: "flex-start",
    },
  },

  image: {
    width: "100% !important",
    alignContent: "center",
    margin: "auto",
    maxHeight: "640px !important",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "0px",
    },
  },
  padding: {
    paddingTop: "160px !important",
    "@media (max-width:780px)": {
      marginTop: "0px !important",
      padding: "30px !important",
    },
  },
  hidden: {
    "@media (max-width:780px)": {
      display: "none !important",
    },
  },
  block: {
    paddingRight: "160px",
    fontWeight: "700",
    fontFamily: "avenir_nextbold",
    "@media (max-width:780px)": {
      marginTop: "60px",
      paddingRight: "10px",
      marginBottom: "40px",
      // fontSize: "34px",
    },
  },
  display: {
    "@media (max-width:780px)": {
      display: "none",
    },
  },
  blackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-end",
    height: "51px",
    width: "225px",
    backgroundColor: "#000",
    color: "#FFF",
    fontFamily: "avenir_nextregular",
    border: "none !important",
    fontSize: "18px",
    marginTop: "40px",
    lineHeight: "27px",
    cursor: "pointer",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "50px",
      marginBottom: "30px",
    },
  },
  yellowBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-end",
    height: "51px",
    width: "225px",
    backgroundColor: "#FFC700",
    color: "#FFF",
    fontFamily: "avenir_nextregular",
    border: "none !important",
    fontSize: "18px",
    marginTop: "40px",
    lineHeight: "27px",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "0px",
      marginBottom: "30px",
    },
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      <Grid container>
        <Grid item md={1} xl={2} sm={1} className={classes.hidden} />
        <Grid item xs={12} md={5} xl={4}>
          <Grid item xs={12} md={12} className={classes.padding}>
            <h2 className={classes.block}>
              Moto seminova, com diárias que cabem no bolso.
            </h2>
            <h5 style={{ marginTop: "24px", fontFamily: "avenir_nextregular" }}>
              Nossa equipe esta pronta para oferecer as melhores motos seminovas
              para você. Além disso, oferecemos diversas opções de pagamento e
              condições especiais, mesmo para negativados.
            </h5>
            <Grid item container>
              <Grid item xs={12} md={6}>
                <div
                  className={classes.blackBtn}
                  onClick={() => {
                    window.open("https://conteudo.goeyja.com.br/goeylpsn");
                  }}
                >
                  Eu quero uma seminova
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ height: "40px !important" }}>
                <div
                  className={classes.yellowBtn}
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send?phone=5511956480925"
                    );
                  }}
                >
                  Consultor no WhatsApp
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.display2}>
          <img src={motoHome} className={classes.image} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page1;
