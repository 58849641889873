//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/franqueado/bg.png";
import "./styles.css";
import { Box, MiniBox, Wrapper, WrapperBox } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "90vh",
    backgroundColor: "#000",
    alignItems: "center",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
      minHeight: "100vh",
    },
  },

  image: {
    // marginTop: "80px !important",
    width: "100% !important",
    alignContent: "center",
    // margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "0px",
    },
  },
  blueBtn: {
    marginTop: "40px",
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #0057FF",
    backgroundColor: "#0057FF",
    color: "#fff",
    maxWidth: "279px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "flex-start",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "45px",
      fontSize: "14px",
      marginTop: "20px",
      // marginBottom: "30px",
    },
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      <Wrapper>
        <Box>
          <h5>Diferente de tudo.</h5>
          <h1>Diferencial imbatível Goey.</h1>
          <h4>
            Você quer empreender com uma margem de lucro maior que as demais
            franquias do mercado ? Abra uma franquia Goey.
            <br />
            <br />
            Muitos pretendem começar a trabalhar com entregas e transporte,
            querem se locomover para o trabalho mas não podem comprar uma moto,
            para isto a opção é a locação por assinatura.
            <br />
            <br />
            Com nossa franquia, você vai poder oferecer motos da melhor marca do
            mercado para uma grande demanda.
          </h4>
          <div
            className={classes.blueBtn}
            onClick={() => {
              window.open("https://conteudo.goeyja.com.br/goeylpsn");
            }}
          >
            Eu quero ser um franqueado
          </div>
        </Box>
        <WrapperBox>
          <MiniBox>
            <h2>Auxílio na obtenção de crédito para crescimento.</h2>
            <h3>
              Criamos um modelo que depois que você adquire as primeiras
              motocicletas auxiliamos você na obtenção de crédito especifico
              para aumento da sua frota, é ai onde você ganha muito mais.
            </h3>
          </MiniBox>
          <MiniBox>
            <h2>Você jogando como os grandes players do mercado.</h2>
            <h3>
              O mesmo com você a partir de uma franquia Goey. Poderá aumentar
              sua frota com crédito de terceiros e lucrar também sobre um
              capital que não é o seu. Alavancagem segura trabalhando por você!
            </h3>
          </MiniBox>

          <MiniBox>
            <h2>Um ecossistema vivo.</h2>
            <h3>
              Goey é um ecossistema que proporciona você lucrar com seu capital,
              com seu lucro e com capital de instituições financeiras e FIDCs
              intermediadas ou não pela Goey.
            </h3>
          </MiniBox>

          <MiniBox>
            <h2>Você pode ir muito mais longe com a Goey.</h2>
            <h3>
              A maioria das franquias vinculam seu crescimento à sua capacidade
              de investimento próprio, ou seja você somente cresce se colocar
              mais dinheiro seu. Na Goey tudo é diferente.
            </h3>
          </MiniBox>
        </WrapperBox>
      </Wrapper>
    </Container>
  );
};

export default Page4;
