//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/bg-f.png";
import "./styles.css";
import { Paper } from "@mui/material";
import {
  Box,
  DualBox,
  MiniBox,
  Wrapper,
  WrapperBox,
  WrapperImg,
  WrapperMiniBox,
} from "./styles";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "rgba(217, 217, 217, 0.40)",
    // marginTop: "80px !important",
    padding: "10px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
    },
  },

  imgBox: {
    maxWidth: "100%",
    width: "100% !important",
    alignContent: "center",
    margin: "auto",
    "@media (max-width:780px)": {
      width: "100% !important",
      height: "auto !important",
      margin: "0px !important",
      padding: "0px !important",
    },
  },
}));

const Page6: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <WrapperBox>
          <h5>Etanol X Elétrico, a diferença que faz a diferença.</h5>
          <h1>Veículos a etanol são menos nocivos que elétricos.</h1>
          <h3>
            Estudos apontam que o derivado da cana é combustível verde
            <br /> (Green Renewable Energy).
          </h3>
        </WrapperBox>
      </Wrapper>
      <DualBox>
        <WrapperMiniBox>
          <MiniBox>
            <h1>Veículo elétrico pode não ser uma boa idéia ainda.</h1>
            <p>
              Ainda existem alguns problemas com veículos elétricos que podem
              mudar sua opinião caso queira um.
            </p>

            <a
              target="_blank"
              href="https://motorandwheels.com/reasons-not-buy-electric-motorcycles/"
              rel="noreferrer"
            >
              Fonte: Motor and Wheels
            </a>
          </MiniBox>

          <MiniBox>
            <h1>Pode não emitir CO2, mas o que ocorre com as baterias?</h1>
            <p>
              Alguns dos elementos da bateria (chumbo-ácido) são tóxicos e
              prejudiciais para saúde/meio ambiente.
            </p>

            <a
              target="_blank"
              href="https://jornal.usp.br/radio-usp/carro-eletrico-pode-ser-bom-o-problema-sao-as-baterias-de-litio/"
              rel="noreferrer"
            >
              Fonte: Jornal da USP
            </a>
          </MiniBox>

          <MiniBox>
            <h1>Existe uma curva de aprendizado no setor ”Eco-friendly”.</h1>
            <p>
              Veículos elétricos não emitem gases que causam poluição no ar, mas
              ainda hà uma curva de amadurecimento no setor.
            </p>

            <a
              target="_blank"
              href="https://futuretransport.com.br/veiculos-eletricos-sao-eco-friendly/"
              rel="noreferrer"
            >
              Fonte: Future Transport
            </a>
          </MiniBox>

          <MiniBox>
            <h1>Estamos de olho no futuro, e ele começa agora.</h1>
            <p>
              Buscamos uma solução completa com excelente autonomia,
              versatilidade e valores compatíveis.
            </p>
          </MiniBox>
        </WrapperMiniBox>
        <Box>
          <img
            src={bg}
            alt="goey"
            className={classes.imgBox}
            style={{ width: "100%" }}
          />
          <WrapperImg>
            <h1>Qual é o futuro dos veículos elétricos?</h1>
            <h5>
              A mobilidade urbana sustentável vem sendo uma preocupação
              constante em todo o mundo, e a Goey participa desta transformação.
            </h5>
          </WrapperImg>
        </Box>
      </DualBox>
      {/* </Grid> */}
    </Container>
  );
};

export default Page6;
