import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 1408px;
  padding: 90px 64px;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
    gap: 0px;
    padding: 20px;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px;
    }
  }
`;

export const Box = styled.div`
  width: 800px;
  flex-shrink: 0;
  margin-top: 90px;
  p {
    align-self: stretch;
    color: #000;
    font-family: "avenir_nextbold" !important;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
  h1 {
    margin-top: 16px;
    font-family: "avenir_nextbold" !important;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
    width: 768px;
  }
  h5 {
    margin-top: 24px;
    color: #000;
    text-align: center;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    width: 768px;
    span {
      color: #000;
      font-family: "avenir_nextbold" !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }

  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
    h5 {
      width: 100% !important;
    }
    h1 {
      width: 100% !important;
      line-height: 100%;
      font-size: 38px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  width: 405.333px;
  flex-direction: column;
  padding: 32px;
  align-self: stretch;
  background: #eee;
  height: 518px;
  justify-content: flex-start;

  h5 {
    color: #000;

    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  h1 {
    color: #000;

    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
  }
  h4 {
    color: #000;

    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  p {
    color: #000;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  @media screen and (max-width: 1380px) {
    flex-direction: column !important;
    width: 365px !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px;
    }
  }

  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px !important;
    }
  }
`;

export const CardShadow = styled.div`
  display: flex;
  width: 405.333px;
  flex-direction: column;
  padding: 32px;
  align-self: stretch;
  background: rgba(217, 217, 217, 0.4);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  height: 518px;
  justify-content: flex-start;
  h5 {
    color: #000;

    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  h1 {
    color: #000;

    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
  }
  h4 {
    color: #000;

    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  p {
    color: #000;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  @media screen and (max-width: 1380px) {
    flex-direction: column !important;
    width: 365px !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px;
    }
  }

  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px;
    }
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 80px;
  justify-content: center;
  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    gap: 20px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #000;
  width: 95%;
  margin-top: 24px;
`;
