import React from "react";
import { Wrapper } from "./styles";
import Relume from "../../../../assets/img/Relume.png";

type CardProps = {
  title: string;
  text: string;
};
function Card({ title, text }: CardProps) {
  return (
    <Wrapper>
      <img src={Relume} style={{ width: "42px", height: "42px" }} />
      <h1>{title}</h1>
      <small>{text}</small>
    </Wrapper>
  );
}

export default Card;
