import styled from 'styled-components';
import { Link, Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

//DESKTOP
export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? 'transparent' : 'transparent')};
  height: 80px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff !important;
  @media screen and (max-width: 780px) {
    transition: 0.8s all ease;
    background: #000;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  z-index: 1;
  width: 100vw;
  padding: 0 24px;
  /* max-width: 1185px !important; */
`;

export const NavLogo = styled(LinkR)`
  color: #000;
  justify-self: flex-start !important;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 200px !important;
  font-weight: bold;
  text-decoration: none;
  margin-left: 30px;
  margin:10px 0 0 200px !important;
  img {
    width: 100px;
  }

  @media screen and (max-width: 768px) {
    margin: 10px 0 0 0 !important;
  }
`;

export const NavHam = styled.div`
  color: #000;
  justify-self: flex-end !important;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-weight: bold;
  text-decoration: none;
  margin-left: 30px;

  img {
    width: 47px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
// export const NavLogin = styled.div`
//   color: #000;
//   justify-self: flex-end;
//   cursor: pointer;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-left: 15px;
//   font-weight: bold;
//   text-decoration: none;
//   margin-left: 30px;
//   width: 70%;
//   img {
//     width: 84px;
//   }

//   @media screen and (max-width: 856px) {
//    display:none !important;
//   }
// `;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
    /* margin-top: 13px; */
  }
`;

export const NavMenu = styled.ul`
  //DESKTOP
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin:0px !important;
  font-weight: 100;
  
  //RESPONSIVE
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 40px;
  
`;

export const NavLinks = styled(LinkS)`
  color: ${({ color }) => (color ? color : '#000')} !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: ${({ bold }) => (bold ? 'avenir_nextbold' : 'avenir_nextregular')} !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  &:hover{
    color: ${({ color }) => (color ? color : '#000')} !important;
  }
  
`;

export const NavBtn = styled.nav`
  //DESKTOP
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #f57c00;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;


`;

export const NavTo = styled(Link)`
  color: ${({ color }) => (color ? color : '#000')} !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: ${({ bold }) => (bold ? 'avenir_nextbold' : 'avenir_nextregular')} !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  &:hover{
    color: ${({ color }) => (color ? color : '#000')} !important;
  }
`