//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/franqueado/bg.png";
import "./styles.css";
import { Box, Wrapper } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "90vh",
    backgroundColor: "#000",
    alignItems: "center",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
      minHeight: "auto",
    },
  },
}));

const Page2: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      <Wrapper>
        <Box>
          <p>TAXA DE FRANQUIA APENAS R$ 1.000,00/MOTO</p>
          <h1>
            ”O mercado que gera mais de 1,6 milhão de empregos no país pode
            aumentar seu patrimônio.”
          </h1>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default Page2;
