//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import star from "../../../assets/img/star.svg";
import starfull from "../../../assets/img/starfull.svg";
import avatar1 from "../../../assets/img/avatar1.png";
import avatar2 from "../../../assets/img/avatar2.png";
import avatar3 from "../../../assets/img/avatar3.png";
import avatar4 from "../../../assets/img/avatar4.png";
import "./styles.css";
import { Paper } from "@mui/material";
import {
  Box,
  BoxPerson,
  Wrapper,
  WrapperBox,
  WrapperCard,
  WrapperPerson,
  WrapperStar,
} from "./styles";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#FFF",
    // marginTop: "80px !important",
    padding: "10px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
    },
  },

  avatar: {
    width: "56px",
    height: "56px",
    borderRadius: "56px",
    "@media (max-width:780px)": {
      width: "56px",
      height: "56px",
      margin: "0px",
      padding: "0px",
    },
  },
}));

const Page7: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <WrapperBox>
          <h1>O que estão dizendo</h1>
          <h5>O que nossos clientes estão dizendo</h5>
        </WrapperBox>
        <WrapperCard>
          <Box>
            <WrapperStar>
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={star} alt={"star"} />
            </WrapperStar>
            <h1>
              "Depois de muito correr atrás consegui uma oportunidade. Estava
              negativado e tive uma chance, o plano é trabalhar muito para
              limpar meu nome e quem sabe aquela moto zero."
            </h1>
            <WrapperPerson>
              <img src={avatar1} alt="goey" className={classes.avatar} />
              <BoxPerson>
                <h4>Kaue Pereira</h4>
                <h5>Honda CG 160 FAN</h5>
              </BoxPerson>
            </WrapperPerson>
          </Box>

          <Box>
            <WrapperStar>
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
            </WrapperStar>
            <h1>
              "Tenho 43 anos, estava desempregado, nome sujo, as contas
              aumentando. Muita gente me chamou de louco, mas a verdade é que
              cada um sabe onde o calo aperta."
            </h1>
            <WrapperPerson>
              <img src={avatar2} alt="goey" className={classes.avatar} />
              <BoxPerson>
                <h4>Peterson Morales</h4>
                <h5>Honda CG 160 Cargo</h5>
              </BoxPerson>
            </WrapperPerson>
          </Box>

          <Box>
            <WrapperStar>
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={star} alt={"star"} />
              <img src={star} alt={"star"} />
              <img src={star} alt={"star"} />
            </WrapperStar>
            <h1>
              "Acho que poderiam ter outros modelos de moto, mas tá valendo."
            </h1>
            <WrapperPerson>
              <img src={avatar3} alt="goey" className={classes.avatar} />
              <BoxPerson>
                <h4>Jailson França</h4>
                <h5>Honda CG 160 FAN</h5>
              </BoxPerson>
            </WrapperPerson>
          </Box>

          <Box>
            <WrapperStar>
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
              <img src={starfull} alt={"star"} />
            </WrapperStar>
            <h1>"Tá valendo, eu to no corre, isso que importa."</h1>
            <WrapperPerson>
              <img src={avatar4} alt="goey" className={classes.avatar} />
              <BoxPerson>
                <h4>Guilherme Santos</h4>
                <h5>Honda CG 160 Sart</h5>
              </BoxPerson>
            </WrapperPerson>
          </Box>
        </WrapperCard>
      </Wrapper>

      {/* </Grid> */}
    </Container>
  );
};

export default Page7;
