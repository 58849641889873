import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  height: auto !important;
  gap: 80px;
  padding: 112px 40px;
  flex-shrink: 0;
  align-items: flex-start;
  @media screen and (max-width: 1380px) {
    padding: 20px;
    width: 100%;
    height: auto;
    gap: 100px;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 10px !important;

    h1 {
      font-size: 42px;
    }
  }
`;

export const DualBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px !important;
  justify-content: flex-start;
  align-items: flex-start;
  h5 {
    color: #0057ff;
    text-align: center;
    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  h1 {
    color: #fff;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
  }
  h4 {
    font-family: "avenir_nextregular" !important;
    color: #fff;
    font-family: Avenir Next;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    /* padding: 10px !important; */
  }
`;

export const WrapperBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 48px;
  width: 820px !important;
  flex-wrap: wrap;
  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
    /* padding: 10px !important; */

    h1 {
      font-size: 42px;
    }
  }
  @media screen and (min-width: 1280px) and (max-width: 1380px) {
    width: 620px !important;
  }
`;

export const MiniBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 386px !important;
  h2 {
    color: #ffc700;
    align-self: stretch;
    /* SmallTittle */
    font-family: "avenir_nextbold" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
  h3 {
    color: #fff;
    align-self: stretch;
    /* bodyTXT */
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  @media screen and (max-width: 1380px) {
    width: 285px !important;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column !important;
    width: 100% !important;
    margin: 0px !important;
  }
`;
