import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 40%;
  height: 100%;
  background: #000;
  display: grid;
  align-items: center;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  @media screen and (max-width: 580px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #FFF;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2.5rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarMenu = styled.ul`
  //DESKTOP
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 90px);
  text-align: left;
  margin: 0;
  padding: 0;
  color:#FFF;
  //RESPONSIVO
  width: 70%;
  @media screen and (max-width: 580px) {
    grid-template-rows: repeat(3, 70px);
    width: 100%;
  }
`;

export const SidebarLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 80px;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;
  text-decoration: none;
  color:#FFFFFF !important;
  cursor: pointer;
  font-weight: 100;
  font-family: 'avenir_nextbold';
  margin: 0 0 0 4rem;
  padding: 0;
  font-size: 32px;
  letter-spacing:-1.25px !important;
  @media screen and (max-width: 580px) {
    margin:2rem 0 0 2rem;
    font-size: 28px;
    line-height: 1;
  }
  &:hover {
    color: #FFF !important;
    transition: 0.2 ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 580px) {
    margin-top: 10px !important;
    grid-template-rows: repeat(6, 80px);
  }
`;

export const SidebarRoute = styled(LinkR)`
  border-radius: 50px;
  background: #f57c00;
  white-space: nowrap;
  padding: 30px 70px;
  color: #010606;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #000;
  }

  @media screen and (max-width: 768px) {
    padding: 15px 69px !important;
    height: 50px !important;
  }
`;
