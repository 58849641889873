import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Page1 from "../../components/Franqueado/Page1";
import Page2 from "../../components/Franqueado/Page2";
import Page3 from "../../components/Franqueado/Page3";
import Page4 from "../../components/Franqueado/Page4";
import Page5 from "../../components/Franqueado/Page5";
import Page6 from "../../components/Franqueado/Page6";

const Franqueado: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
      <Footer />
    </>
  );
};

export default Franqueado;
