import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 80px 10% 50px 10%;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 80px; */
  background-color: ${({ color }) => (color ? color : "#fff")} !important;

  @media screen and (max-width: 780px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  width: 768px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  h5 {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    align-self: stretch;
  }
  h1 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
  }
  p {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    align-self: stretch;
  }

  @media screen and (max-width: 700px) {
    width: 100% !important;
    padding: 10px;
    h1 {
      line-height: 50px;
    }
  }
`;

export const DualBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  h3 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    width: 500px;
  }

  h4 {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  @media screen and (max-width: 780px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    h3 {
      width: 100%;
    }
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #000;
  width: 95%;
  margin-bottom: 20px;
`;
export const LineBottom = styled.div`
  height: 1px;
  background: #000;
  width: 95%;
  margin-top: 40px;
`;
export const WrapperDual = styled.div`
  display: flex;
  padding: 20px 10% 10px 10%;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 80px; */
  background-color: ${({ color }) => (color ? color : "#fff")} !important;

  @media screen and (max-width: 780px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
`;
