import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  width: 1276px;
  height: auto !important;
  gap: 80px;
  padding: 50px 40px;
  flex-shrink: 0;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 20px;
  }
  @media screen and (min-width: 1980px) {
    margin-top: 30px;
  }
`;

export const DualBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 664px;

  h5 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  h1 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 120%; /* 57.6px */
  }
  p {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    align-self: stretch;
  }

  @media screen and (max-width: 700px) {
    width: 100% !important;
    padding: 10px;
  }
`;
