//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import goey from "../../../assets/img/goey.png";
import "./styles.css";
import { Box, Wrapper } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: `rgba(217, 217, 217, 0.40)`,
    alignItems: "center",
    padding: "100px !important",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
    },
  },

  image: {
    marginTop: "80px !important",
    width: "65% !important",
    alignContent: "center",
    margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100vw !important",
      height: "auto",
      margin: "0px !important",
      padding: "20px !important",
    },
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container} id="goey">
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <Box>
          <h5>Sobre a Goey</h5>
          <h1>
            Nossos valores fazem parte de tudo construído aqui, tudo para você.
          </h1>
        </Box>
        <Box>
          <p>
            Nossa missão é conectar mobilidade urbana a mobilidade social e meio
            ambiente para uma vida mais justa e sustentável.
            <br />
            <br />
            Operando de forma inovadora serviços de locação de moto para tornar
            mais fácil a vida de quem utiliza a moto como principal meio de
            transporte. Explorando uma cultura colaborativa de inclusão,
            crescimento e originalidade, apoiada por recursos que fazem a
            diferença na vida de quem utiliza nossos serviços.
            <br />
            <br />
            Nosso legado é impactar nossos clientes, colegas e o planeta. Uma
            trilha de integração de pessoas e oportunidades.
          </p>
        </Box>
      </Wrapper>
      <img src={goey} alt="goey" className={classes.image} />
    </Container>
  );
};

export default Page4;
