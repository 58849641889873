//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/franqueado/bg.png";
import "./styles.css";
import { Box, Card, CardShadow, Line, Wrapper, WrapperCard } from "./styles";

import { MdCheck } from "react-icons/md";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#fff",
    alignItems: "center",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start",
    "@media (max-width:780px)": {
      margin: "0px !important",
      // padding: "10px !important",
      justifyContent: "flex-start",
    },
  },
  blackBtn: {
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // gap: "8px",
    border: "1px solid #000",
    backgroundColor: "#000",
    color: "#fff",
    // maxWidth: "279px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "0px",
      marginBottom: "30px",
    },
  },
}));

const Page3: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container className={classes.Container}>
      <Wrapper>
        <Box>
          <p>/ Modelos de Franquia / </p>
          <h1>Escolhendo a melhor franquia de sucesso para você!</h1>
          <h5>
            Descubra como faturar até <span>R$ 20.000,00</span> por mês ,
            trabalhando 1 hora por dia e sem dor de cabeça. Entregamos nosso
            operacional e know how para sua segurança e tranquilidade.
          </h5>
        </Box>
        <WrapperCard>
          <Card>
            <h5>Goey G5</h5>
            <h1>R$80.000,00</h1>
            <h4>Taxa de Franquia: R$ 5.000,00</h4>
            <Line />
            <h5 style={{ marginTop: "32px" }}>Pacote G5: 5 motos 160CC</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Marketing e Prospecção de Leads.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Monitoramento & Rastreio 24h.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",

                height: "24px !important",
              }}
            >
              <MdCheck size={42} />
              <p>
                Estrutura Operacional Completa (cobrança, multas, coletas,
                atendimento).
              </p>
            </div>
            <div
              className={classes.blackBtn}
              onClick={() => scrollToDiv("formFranqueado")}
            >
              Seja um franqueado G5
            </div>
          </Card>

          <CardShadow>
            <h5>Goey G10</h5>
            <h1>R$160.000,00</h1>
            <h4>Taxa de Franquia: R$ 10.090,00</h4>
            <Line />
            <h5 style={{ marginTop: "32px" }}>Pacote G15: 10 motos 160CC</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Marketing e Prospecção de Leads.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Monitoramento & Rastreio 24h.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",

                height: "24px !important",
              }}
            >
              <MdCheck size={42} />
              <p>
                Estrutura Operacional Completa (cobrança, multas, coletas,
                atendimento).
              </p>
            </div>
            <div
              className={classes.blackBtn}
              onClick={() => scrollToDiv("formFranqueado")}
            >
              Seja um franqueado G10
            </div>
          </CardShadow>

          <Card>
            <h5>Goey G15</h5>
            <h1>R$240.000,00</h1>
            <h4>Taxa de Franquia: R$ 15.000,00</h4>
            <Line />
            <h5 style={{ marginTop: "32px" }}>Pacote G15: 15 motos 160CC</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Marketing e Prospecção de Leads.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "16px",
                minHeight: "24px",
              }}
            >
              <MdCheck size={24} />
              <p>Monitoramento & Rastreio 24h.</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",

                height: "24px !important",
              }}
            >
              <MdCheck size={42} />
              <p>
                Estrutura Operacional Completa (cobrança, multas, coletas,
                atendimento).
              </p>
            </div>
            <div
              className={classes.blackBtn}
              onClick={() => scrollToDiv("formFranqueado")}
            >
              Seja um franqueado G15
            </div>
          </Card>
        </WrapperCard>
      </Wrapper>
    </Container>
  );
};

export default Page3;
