//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/franqueado/bg.png";
import "./styles.css";
import { Box, Wrapper } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "95vh",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      // padding: "10px !important",
      justifyContent: "flex-start",
      minHeight: "100vh",
    },
  },

  image: {
    // marginTop: "80px !important",
    width: "100% !important",
    alignContent: "center",
    height: "675px",
    // margin: "auto",
    // padding:'15px',
    "@media (max-width:1280px)": {
      width: "100%",
      height: "370px",
      margin: "0px",
      padding: "0px",
    },
    "@media (max-width:600px)": {
      margin: "0px",
      width: "100%",
      height: "300px",
      padding: "0px",
    },
  },
  blackBtn: {
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #000",
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: "279px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    height: "49px",
    "@media (max-width:780px)": {
      margin: "0px",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      maxWidth: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "0px",
      // marginBottom: "30px",
    },
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      {/* <Grid item container spacing={10}> */}
      <img src={bg} alt="goey" className={classes.image} />
      <Wrapper>
        <Box>
          <h1>Franquia Goey, segura e com grande potencial de crescimento.</h1>
        </Box>
        <Box>
          <p>
            Invista na franquia mais lucrativa do momento: alugue motos por
            assinatura para entregadores, transportes de aplicativos e usuários
            em geral. Segurança e lucratividade em franquia. Ganhe mais rápido
            com a unica franquia de motos por assinatura do momento que te
            auxilia a escalar o mercado e aumentar seu patrimônio.
          </p>
          <div
            className={classes.blackBtn}
            onClick={() => {
              window.open("https://conteudo.goeyja.com.br/goeylpsn");
            }}
          >
            Eu quero ser um franqueado
          </div>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default Page1;
