//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import home2pg from "../../../assets/img/home-2pg.png";
import "./styles.css";
import { Box, BoxBody, BoxTitle, DualBox, Wrapper } from "./styles";
import { MdCheck } from "react-icons/md";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#FFC000 !important",
    // marginTop: "80px !important",
    padding: "10px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
    },
  },

  image: {
    maxWidth: "640px",
    width: "100% !important",
    alignContent: "center",
    margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "0px",
    },
  },
  padding: {
    paddingTop: "160px !important",
    "@media (max-width:780px)": {
      marginTop: "0px !important",
      padding: "30px !important",
    },
  },
  hidden: {
    "@media (max-width:780px)": {
      display: "none !important",
    },
  },
  block: {
    paddingRight: "160px",
    fontWeight: "700",
    "@media (max-width:780px)": {
      marginTop: "60px",
      paddingRight: "10px",
      marginBottom: "40px",
      // fontSize: "34px",
    },
  },
  display: {
    "@media (max-width:780px)": {
      display: "none",
    },
  },
  blackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-end",
    height: "51px",
    width: "auto",
    backgroundColor: "#000",
    color: "#FFF",
    fontFamily: "avenir_nextregular",
    border: "none !important",
    fontSize: "18px",
    marginTop: "80px",
    lineHeight: "27px",
    cursor: "pointer",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "50px",
      marginBottom: "30px",
    },
  },
  p60: {
    marginTop: "60px !important",
    "@media (max-width:780px)": {
      marginTop: "5px !important",
    },
  },
  sobre: {
    flexDirection: "row",
    display: "flex",
    gap: "8px",
    marginTop: "48px !important",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width:780px)": {},
  },
}));

const Page3: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <h3>Não é consórcio</h3>
        <h1>O plano perfeito para você</h1>
        <small>
          Locação por assinatura, sua melhor opção em mobilidade! Flexibilidade
          da locação e a moto ainda pode ser sua no final!
        </small>
      </Wrapper>
      <DualBox>
        <Box>
          <BoxTitle>
            <p>Total de Meses</p>
            <h5>36</h5>
            <p>Período de 3 anos</p>
          </BoxTitle>
          <BoxBody>
            <MdCheck size={24} />
            <p>Entrada mínima R$ 1.000,00</p>
          </BoxBody>
          <BoxBody>
            <MdCheck size={24} />
            <p>Pagamentos semanais para melhor controle</p>
          </BoxBody>
          <BoxBody>
            <MdCheck size={24} />
            <p>Motos Seminovas revisadas</p>
          </BoxBody>
          <div
            className={classes.blackBtn}
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5511956480925&text=Quero%20falar%20sobre%20o%20plano%20de%2036%20meses"
              );
            }}
          >
            Quero falar com um consultor
          </div>
        </Box>
        <Box>
          <BoxTitle>
            <p>Total de Meses</p>
            <h5>48</h5>
            <p>Período de 4 anos</p>
          </BoxTitle>
          <BoxBody>
            <MdCheck size={24} />
            <p>Entrada mínima de R$ 1.500,00</p>
          </BoxBody>
          <BoxBody>
            <MdCheck size={24} />
            <p>Pagamentos semanais para melhor controle</p>
          </BoxBody>
          <BoxBody>
            <MdCheck size={24} />
            <p>Motos Seminovas revisadas</p>
          </BoxBody>
          <div
            className={classes.blackBtn}
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5511956480925&text=Quero%20falar%20sobre%20o%20plano%20de%2048%20meses"
              );
            }}
          >
            Quero falar com um consultor
          </div>
        </Box>
      </DualBox>
      {/* </Grid> */}
    </Container>
  );
};

export default Page3;
