//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import home2pg from "../../../assets/img/home-2pg.png";
import "./styles.css";
import Card from "./card";
import { IoIosArrowForward } from "react-icons/io";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#E9E9E9 !important",
    // marginTop: "80px !important",
    padding: "15px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "0px !important",
      justifyContent: "flex-start",
    },
  },

  image: {
    maxWidth: "640px",
    width: "100% !important",
    alignContent: "center",
    margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "0px",
    },
  },
  padding: {
    paddingTop: "160px !important",
    "@media (max-width:780px)": {
      marginTop: "0px !important",
      padding: "30px !important",
    },
  },
  hidden: {
    "@media (max-width:780px)": {
      display: "none !important",
    },
  },
  block: {
    paddingRight: "160px",
    fontWeight: "700",
    "@media (max-width:780px)": {
      marginTop: "60px",
      paddingRight: "10px",
      marginBottom: "40px",
      // fontSize: "34px",
    },
  },
  display: {
    "@media (max-width:780px)": {
      display: "none",
    },
  },
  blackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-end",
    height: "51px",
    width: "225px",
    backgroundColor: "#000",
    color: "#FFF",
    fontFamily: "avenir_nextregular",
    border: "none !important",
    fontSize: "18px",
    marginTop: "48px",
    lineHeight: "27px",
    cursor: "pointer",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "50px",
      marginBottom: "10px",
    },
  },
  p60: {
    marginTop: "60px !important",
    "@media (max-width:780px)": {
      marginTop: "5px !important",
    },
  },
  sobre: {
    flexDirection: "row",
    display: "flex",
    gap: "8px",
    marginTop: "48px !important",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width:780px)": {
      marginTop: "0px !important",
    },
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    marginTop: "15px",
    "@media (max-width:780px)": {
      flexDirection: "column",
      margin: "20px",
    },
  },
  wrapperbtn: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    marginTop: "32px",
    "@media (max-width:780px)": {
      flexDirection: "column",
      margin: "20px",
    },
  },
  display2: {
    "@media (max-width:780px)": {
      paddingTop: "0px !important",
    },
  },
}));

const Page2: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container className={classes.Container}>
      <Grid item container spacing={10}>
        <Grid item md={1} sm={1} />

        <Grid item xs={12} md={5} className={classes.display2}>
          <img src={home2pg} className={classes.image} />
        </Grid>
        <Grid item xs={12} md={5} className={classes.p60}>
          <div className={classes.cards}>
            <Card
              title="Oportunidade única"
              text="Mesmo negativado você tem grandes chances de ser aprovado e conquistar a sua seminova."
            />{" "}
            <Card
              title="Investindo em você"
              text="Lembre-se você não está investindo em você, em uma nova oportunidade."
            />
          </div>
          <div className={classes.cards}>
            <Card
              title="Veículos revisados"
              text="Todos os veículos passam por revisão em nossa oficina, e tem garantia, pode ficar tranquilo."
            />{" "}
            <Card
              title="Os melhores modelos"
              text="Você esta investindo em você criando uma nova oportunidade de ganho."
            />
          </div>
          <div className={classes.wrapperbtn}>
            <div
              className={classes.blackBtn}
              onClick={() => {
                window.open("https://conteudo.goeyja.com.br/goeylpsn");
              }}
            >
              Eu quero uma seminova
            </div>
            <div className={classes.sobre} onClick={() => scrollToDiv("goey")}>
              Sobre nós{" "}
              <IoIosArrowForward style={{ marginTop: "2px" }} size={24} />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page2;
