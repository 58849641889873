import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  width: 1276px !important;
  height: auto !important;
  gap: 80px;
  padding: 112px 40px;
  flex-shrink: 0;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 20px;
    padding: 0px;
    height: auto !important;
  }
`;

export const Box = styled.div`
  width: 800px;
  height: 202px;
  flex-shrink: 0;

  h1 {
    color: #fff;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }
  p {
    font-family: "avenir_nextbold" !important;
    color: #ffc700;
    font-family: Avenir Next;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  @media screen and (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 20px;

    h1 {
      font-size: 45px;
    }
  }
`;
