//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./styles.css";
import { Wrapper, WrapperForm, WrapperText } from "./styles";
import { Button, Form, FormGroup } from "react-bootstrap";
import axios from "axios";
import { Alert } from "@mui/material";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "90vh",
    backgroundColor: "#fff",
    alignItems: "center",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      // padding: "10px !important",
      justifyContent: "flex-start",
      // minHeight: "100vh !important",
    },
  },

  image: {
    // marginTop: "80px !important",
    width: "100% !important",
    alignContent: "center",
    // margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "0px",
    },
  },
  blueBtn: {
    marginTop: "40px",
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #000",
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: "279px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    textAlign: "center",
    "@media (max-width:780px)": {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "14px",
      marginTop: "0px",
      marginBottom: "30px",
    },
  },
  label: {
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "150% !important",
    alignSelf: "stretch !important",
  },
}));

const Page5: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const sendEmail = async (e: any) => {
    e.preventDefault();
    const data = {
      mensagem: `Nome: ${e.target[0].value} // E-mail: ${e.target[1].value} // Telefone: ${e.target[2].value}`,
      auth: "3f018bfb43591ccb2ef46f10de832160",
      assunto: "Form Goey",
      destino: "marcus.cominatto@gmail.com",
    };
    if (e.target[3].checked) {
      const response = await axios.post(
        "https://api.goey.com.br/ws/goey/rest/public/envioEmail",
        data
      );

      alert(response.data?.mensagem);
    } else {
      alert("Precisa marcar a caixa acima.");
    }
  };

  return (
    <Container className={classes.Container} id="formFranqueado">
      <Wrapper>
        <WrapperText>
          <h1>
            Você só precisa de <span>1h</span> por dia para administrar sua
            franquia.
          </h1>
          <h5>
            <span>
              Se você quer diversificar suas fontes de renda, mas não tem tempo
              para construir do zero um outro negócio?
            </span>{" "}
            A franquia Goey é a melhor opção. Em apenas uma hora do seu dia você
            consegue olhar os requerimentos da empresa e resolver o que precisa.
          </h5>
        </WrapperText>
        <WrapperForm>
          <h1>Seja um franqueado</h1>
          <p>Receba o contato da nossa equipe de especialistas</p>
          <Form onSubmit={sendEmail}>
            <Form.Group className="mb-3">
              <Form.Label className={classes.label}>Nome</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className={classes.label}>Email</Form.Label>
              <Form.Control type="email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className={classes.label}>Telefone</Form.Label>
              <Form.Control type="phone" />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Form.Check
                type="checkbox"
                label="Clicando aqui você aceita receber o contato do nosso time e informações
a respeito de oportunidades, e outros sobre Goey Mobilidade."
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button type="submit" className={classes.blueBtn}>
                Enviar meus dados
              </Button>
            </Form.Group>
          </Form>
        </WrapperForm>
      </Wrapper>
    </Container>
  );
};

export default Page5;
