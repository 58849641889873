import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  width: 1276px !important;
  height: auto !important;
  gap: 80px;
  padding: 112px 40px;
  flex-shrink: 0;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
    gap: 20px;
    padding: 20px;
  }
`;

export const DualBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 598px !important;

  h5 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  h1 {
    color: #000;
    font-family: "avenir_nextbold" !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    margin-top: 16px;
  }
  p {
    color: #000;
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
    width: 100% !important;

    h1 {
      color: #000;
      font-family: "avenir_nextbold" !important;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 57.6px */
      margin-top: 16px;
    }
  }
`;
