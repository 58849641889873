import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: flex-start;
  margin-left: 80px;
  margin-right: 80px;

  @media screen and (max-width: 780px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const WrapperBox = styled.div`
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 80px;

  @media screen and (max-width: 780px) {
    padding: 10px;
    margin-top: 12px;
  }

  h5 {
    font-family: "Roboto" !important;
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-align: center;
  }
  h1 {
    font-family: "avenir_nextbold" !important;
    color: #000 !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
  }
  h4 {
    font-family: "Roboto" !important;
    color: #000 !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
`;

export const WrapperContact = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  padding: 0 64px;
  margin-top: 60px;

  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
  }
`;

export const BoxContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 394px;
  max-width: 394px !important;

  @media screen and (max-width: 780px) {
    width: 100%;
  }

  h5 {
    font-family: "avenir_nextbold" !important;
    color: #000 !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    margin-top: 24px;
  }
  p {
    font-family: "Roboto" !important;
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-top: 16px;
  }
  small {
    font-family: "Roboto" !important;
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-decoration-line: underline;
    margin-top: 10px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #000;
  width: 95%;
  margin-top: 62px;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 448px;

  @media screen and (max-width: 1380px) {
    width: 100% !important;
    h1 {
      font-size: 36px !important;
    }
  }

  @media screen and (max-width: 780px) {
    width: 100% !important;
    h1 {
      font-size: 38px !important;
    }
  }

  h5 {
    font-family: "avenir_nextbold" !important;
    color: #0057ff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
  }
  h1 {
    font-family: "Roboto" !important;
    color: #000 !important;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }
  h3 {
    color: #000 !important;
    font-family: "avenir_nextregular" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 140%; /* 33.6px */
  }

  small {
    color: #000 !important;
    font-family: "avenir_nextbold" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    margin-bottom: 24px;
  }
  h4 {
    strong {
      font-weight: 700;
    }
    font-family: "avenir_nextregular" !important;
    color: #0057ff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const WrapperInfo = styled.div`
  display: flex;
  padding: 64px;
  align-items: flex-start;
  gap: 48px;

  @media screen and (max-width: 1380px) {
    width: 100% !important;
  }
  @media screen and (max-width: 780px) {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Social = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  height: 43px;
  cursor: pointer;
  @media screen and (max-width: 780px) {
    justify-content: flex-start;
  }

  p {
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }
`;
