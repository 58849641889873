/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
// import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import menu from '../../assets/images/Menu.svg';
import login from '../../assets/images/Login.svg';
import goey from '../../assets/images/goey_LogoPreto@2x.png';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  // MobileIcon,
  //   NavMenu,
  NavHam,
  NavLogin,
  NavItem,
  NavLinks,
  NavMenu,
  MobileIcon,
  NavTo,
} from '../Navbar/NavbarElements';
import { FaBars } from 'react-icons/fa';

//MENU
const Navbar = ({ toggle }) => {
  //FUNCAO SCROLL
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#000' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <img className="LogoPrincipal" src={goey} />
            </NavLogo>
            {/* <NavHam >
              <img className="LogoPrincipal" src={menu} onClick={toggle} />
            </NavHam> */}

            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>

            <NavMenu>
              {
                window.location.pathname === '/franqueado' ?
                  <NavItem>
                    <NavTo
                      to="/"
                      duration={500}
                      spy={true}
                      exact='true'
                      offset={-80}
                      color={"#000"}
                    >A Goey</NavTo>
                  </NavItem>
                  :
                  <NavItem>
                    <NavLinks to='goey'
                      smoooth="true"
                      duration={500}
                      spy={true}
                      exact='true'
                      offset={-80}
                      color={"#000"}
                    >A Goey</NavLinks>
                  </NavItem>

              }


              <NavItem>
                <NavLinks to='contato'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}

                >Contato</NavLinks>
              </NavItem>

              <NavItem>
                <NavTo
                  to="/franqueado"
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  color={"blue"}
                >| Seja um Franqueado |</NavTo>
              </NavItem>

              <NavItem >
                <NavLinks
                  onClick={() => { window.open('https://www.ddtotal.com.br/consultaboletos/', "_blank") }}
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  bold="true"
                >2a Via Boleto</NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

//yarn add styled-components
//yarn add react-icons
//yarn add react-scroll
