//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import moto from "../../../assets/img/moto.png";
import "./styles.css";
import { Box, Wrapper } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    padding: "100px !important",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "20px !important",
      justifyContent: "flex-start",
      minHeight: "auto !important",
    },
  },

  image: {
    marginTop: "80px !important",
    width: "65% !important",
    alignContent: "center",
    margin: "auto",
    // padding:'15px',
    "@media (max-width:780px)": {
      width: "100vw !important",
      height: "auto",
      margin: "0px !important",
      padding: "20px !important",
    },
  },
  yellowBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-end",
    height: "51px",
    width: "234px",
    backgroundColor: "#FFC700",
    color: "#000",
    fontFamily: "avenir_nextregular",
    border: "none !important",
    fontSize: "18px",
    marginTop: "40px",
    lineHeight: "27px",
    fontWeight: "700 !important",
    "@media (max-width:780px)": {
      fontFamily: "avenir_nextbold !important",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      height: "49px",
      fontSize: "18px",
      marginTop: "0px",
      marginBottom: "30px",
    },
  },
}));

const Page5: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <Box>
          <h1>Uma nova chance, um recomeço, mude de vida.</h1>
        </Box>
        <Box>
          <p>
            Isso mesmo, uma nova chance. Nós sabemos, você sabe, moto podemos
            encontrar em qualquer lugar, mas oportunidade é completamente
            diferente. Você está preparado?
          </p>
          <div
            className={classes.yellowBtn}
            onClick={() => {
              window.open("https://conteudo.goeyja.com.br/goeylpsn");
            }}
          >
            Quero mudar de vida
          </div>
        </Box>
      </Wrapper>
      <img src={moto} alt="goey" className={classes.image} />
    </Container>
  );
};

export default Page5;
