//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import star from "../../../assets/img/star.svg";
import "./styles.css";
import { Paper } from "@mui/material";
import {
  Box,
  BoxContact,
  BoxInfo,
  BoxPerson,
  Line,
  Social,
  Wrapper,
  WrapperBox,
  WrapperCard,
  WrapperContact,
  WrapperInfo,
  WrapperPerson,
  WrapperStar,
} from "./styles";
import { MdEmail, MdLocalPhone, MdLocationPin } from "react-icons/md";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: "#FFC000",
    // marginTop: "80px !important",
    padding: "10px !important",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start",
    "@media (max-width:780px)": {
      margin: "0px !important",
      padding: "10px !important",
      justifyContent: "flex-start",
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container} id="contato">
      {/* <Grid item container spacing={10}> */}
      <Wrapper>
        <WrapperBox>
          <h5>Em caso de dúvidas/contato</h5>
          <h1>Fale conosco</h1>
          <h4>Horário Administrativo Seg-Sex das 8h00hs até 18:00hs</h4>
        </WrapperBox>

        <WrapperContact>
          <BoxContact>
            <MdEmail size={48} color="#000" />
            <h5>Email</h5>
            <p>
              Nosso email está a sua disposição. Envie suas dúvidas,
              comentários.
            </p>
            <small>contato@goeyja.com.br</small>
          </BoxContact>

          <BoxContact>
            <MdLocalPhone size={48} color="#000" />
            <h5>Atendimento</h5>
            <p>
              Quer falar com a gente e não sabe por onde começar. No número
              abaixo você terá o direcionamento correto.
            </p>
            <small>(19) 99967-3284</small>
          </BoxContact>

          <BoxContact>
            <MdLocationPin size={48} color="#000" />
            <h5>Base</h5>
            <p>
              Todo nosso processo é online, mas abaixo você tem o endereço do
              nosso ponto de entrega/devolução.
            </p>
            <small>Rua Candido do Vale n° 307 - Tatuapé - São Paulo/SP</small>
          </BoxContact>
        </WrapperContact>
        <Line />

        <WrapperInfo>
          <BoxInfo>
            <h5>Furto & Roubo (24h).</h5>
            <h1>(19) 98171-8095</h1>
          </BoxInfo>

          <BoxInfo>
            <h4>
              <strong>__Clique Aqui</strong>, e seja um franqueado..
            </h4>
            <h3>
              Franquia Goey, segura e com grande potencial de crescimento.
            </h3>
          </BoxInfo>

          <BoxInfo>
            <small>Siga-nos</small>
            <Social
              onClick={() => {
                window.open("https://www.facebook.com/goeyja");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 12.3038C22 6.74719 17.5229 2.24268 12 2.24268C6.47715 2.24268 2 6.74719 2 12.3038C2 17.3255 5.65684 21.4879 10.4375 22.2427V15.2121H7.89844V12.3038H10.4375V10.0872C10.4375 7.56564 11.9305 6.1728 14.2146 6.1728C15.3088 6.1728 16.4531 6.36931 16.4531 6.36931V8.84529H15.1922C13.95 8.84529 13.5625 9.6209 13.5625 10.4166V12.3038H16.3359L15.8926 15.2121H13.5625V22.2427C18.3432 21.4879 22 17.3257 22 12.3038Z"
                  fill="black"
                />
              </svg>
              <p>Facebook</p>
            </Social>

            <Social
              onClick={() => {
                window.open("https://www.instagram.com/goeyja/");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 3.24219H8C5.23858 3.24219 3 5.48077 3 8.24219V16.2422C3 19.0036 5.23858 21.2422 8 21.2422H16C18.7614 21.2422 21 19.0036 21 16.2422V8.24219C21 5.48077 18.7614 3.24219 16 3.24219ZM19.25 16.2422C19.2445 18.0348 17.7926 19.4867 16 19.4922H8C6.20735 19.4867 4.75549 18.0348 4.75 16.2422V8.24219C4.75549 6.44954 6.20735 4.99768 8 4.99219H16C17.7926 4.99768 19.2445 6.44954 19.25 8.24219V16.2422ZM16.75 8.49219C17.3023 8.49219 17.75 8.04447 17.75 7.49219C17.75 6.93991 17.3023 6.49219 16.75 6.49219C16.1977 6.49219 15.75 6.93991 15.75 7.49219C15.75 8.04447 16.1977 8.49219 16.75 8.49219ZM12 7.74219C9.51472 7.74219 7.5 9.75691 7.5 12.2422C7.5 14.7275 9.51472 16.7422 12 16.7422C14.4853 16.7422 16.5 14.7275 16.5 12.2422C16.5027 11.0479 16.0294 9.90176 15.1849 9.05727C14.3404 8.21278 13.1943 7.73953 12 7.74219ZM9.25 12.2422C9.25 13.761 10.4812 14.9922 12 14.9922C13.5188 14.9922 14.75 13.761 14.75 12.2422C14.75 10.7234 13.5188 9.49219 12 9.49219C10.4812 9.49219 9.25 10.7234 9.25 12.2422Z"
                  fill="black"
                />
              </svg>
              <p>Instagram</p>
            </Social>

            <Social
              onClick={() => {
                window.open("https://www.linkedin.com/company/goeymobilidade/");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.5 3.24219C3.67157 3.24219 3 3.91376 3 4.74219V19.7422C3 20.5706 3.67157 21.2422 4.5 21.2422H19.5C20.3284 21.2422 21 20.5706 21 19.7422V4.74219C21 3.91376 20.3284 3.24219 19.5 3.24219H4.5ZM8.52076 7.24491C8.52639 8.20116 7.81061 8.79038 6.96123 8.78616C6.16107 8.78194 5.46357 8.14491 5.46779 7.24632C5.47201 6.40116 6.13998 5.72194 7.00764 5.74163C7.88795 5.76132 8.52639 6.40679 8.52076 7.24491ZM12.2797 10.0039H9.75971H9.7583V18.5638H12.4217V18.3641C12.4217 17.9842 12.4214 17.6042 12.4211 17.2241C12.4203 16.2103 12.4194 15.1954 12.4246 14.1819C12.426 13.9358 12.4372 13.6799 12.5005 13.445C12.7381 12.5675 13.5271 12.0008 14.4074 12.1401C14.9727 12.2286 15.3467 12.5563 15.5042 13.0893C15.6013 13.4225 15.6449 13.7811 15.6491 14.1285C15.6605 15.1761 15.6589 16.2237 15.6573 17.2714C15.6567 17.6412 15.6561 18.0112 15.6561 18.381V18.5624H18.328V18.3571C18.328 17.9051 18.3278 17.4532 18.3275 17.0013C18.327 15.8718 18.3264 14.7423 18.3294 13.6124C18.3308 13.1019 18.276 12.5985 18.1508 12.1049C17.9638 11.3708 17.5771 10.7633 16.9485 10.3246C16.5027 10.0124 16.0133 9.81129 15.4663 9.78879C15.404 9.7862 15.3412 9.78281 15.2781 9.7794C14.9984 9.76428 14.7141 9.74892 14.4467 9.80285C13.6817 9.95613 13.0096 10.3063 12.5019 10.9236C12.4429 10.9944 12.3852 11.0663 12.2991 11.1736L12.2797 11.1979V10.0039ZM5.68164 18.5666H8.33242V10.0095H5.68164V18.5666Z"
                  fill="black"
                />
              </svg>
              <p>LinkedIn</p>
            </Social>
          </BoxInfo>
        </WrapperInfo>
      </Wrapper>

      {/* </Grid> */}
    </Container>
  );
};

export default Footer;
