import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    //styleName: SmallTittle;
    font-family: "avenir_nextbold" !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
  }
  small {
    font-family: "avenir_nextregular" !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
