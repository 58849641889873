import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 50%);

  @media screen and (max-width: 780px) {
    grid-template-columns: repeat(1, 100%);
    height: auto;
  }
`;

export const WrapperText = styled.div`
  background: #0057ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    width: 568px;
    color: #fff;
    font-family: "avenir_nextbold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    span {
      color: #fff;
      font-family: "avenir_nextbold";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
  h5 {
    width: 568px;
    color: #fff;
    font-family: "avenir_nextregular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    span {
      color: #ffc700;
      font-family: "avenir_nextbold";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
  }
  @media screen and (max-width: 780px) {
    padding: 20px !important;
    width: 100% !important;

    h1 {
      padding-top: 40px;
      font-size: 42px;
      width: 100% !important;
      line-height: 60px;
    }
    h5 {
      width: 100%;
    }
  }
`;
export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 688px;
  margin: auto;

  h1 {
    color: #000;
    text-align: center;
    font-family: "avenir_nextbold";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 145.833% */
    align-self: stretch;
  }
  p {
    color: #000;
    text-align: center;
    font-family: "avenir_nextregular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    align-self: stretch;
    line-height: 150%; /* 27px */
  }
  @media screen and (max-width: 1380px) {
    width: 100% !important;
    padding: 10px;
  }
  @media screen and (max-width: 780px) {
    padding: 20px !important;
    width: 100% !important;
    h1 {
      padding-top: 40px;
      font-size: 42px;
      width: 100% !important;
      line-height: 60px;
    }
    h5 {
      width: 100%;
    }
  }
`;
