//@ts-nocheck
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { makeStyles, Theme } from "@mui/styles";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import bg from "../../../assets/img/franqueado/bg.png";
import "./styles.css";
import { Box, DualBox, Line, LineBottom, Wrapper, WrapperDual } from "./styles";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: "100%",
    minHeight: "95vh",
    backgroundColor: "#FFFFFF",
    display: "flex !important",
    flexDirection: "column !important",

    "@media (max-width:780px)": {
      margin: "0px !important",
      // padding: "10px !important",
      justifyContent: "flex-start",
      minHeight: "100vh",
    },
  },

  image: {
    // marginTop: "80px !important",
    width: "100% !important",
    alignContent: "center",
    height: "675px",
    // margin: "auto",
    // padding:'15px',
    "@media (max-width:1280px)": {
      width: "100%",
      height: "370px",
      margin: "0px",
      padding: "0px",
    },
    "@media (max-width:600px)": {
      margin: "0px",
      width: "100%",
      height: "300px",
      padding: "0px",
    },
  },
  blackBtn: {
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #000",
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: "279px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "@media (max-width:780px)": {
      margin: "0px",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100% !important",
      maxWidth: "100% !important",
      // height: "35px",
      fontSize: "14px",
      marginTop: "0px",
      // marginBottom: "30px",
    },
  },
}));

const Page6: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      <Wrapper color="#fff">
        <Box>
          <h1>Mais fácil do que você imagina</h1>
          <h5>
            Não é comparação, é apenas a realidade de como é ter uma franquia
            Goey. Confira abaixo alguns dos pontos que tornam a Goey um
            investimento atraente.
          </h5>
        </Box>
      </Wrapper>
      <WrapperDual color="#FFC700">
        <Line />
        <DualBox>
          <h3>
            Como é o modelo de negócio de Franquias da Goey, fique por dentro.
          </h3>
          <h4>
            __ Você já ganha no primeiro mês.
            <br />
            __ Sem necessidade de contratação de funcionários e riscos
            trabalhistas.
            <br />
            __ Garage Office (sua garagem como loja).
            <br />
            __ Facilidade na gestão compartilhada.
            <br />
            __ Não exige tempo integral.
            <br />
            __ Auxílio na obtenção de crédito, foco em desenvolvimento do
            negócio.
            <br />
            __ Ganhos com capital investido + frota.
            <br />
            __ Trabalhe com as melhores marcas do mercado.
            <br />
            __ Tecnologia de rastreamento/monitoramento embarcado.
            <br />
            __ Goey opera neste mercado há 04 (quatro) anos e possui frota
            própria de 535 motos.
            <br />
            __ Aplicativos e softwares proprietários para gestão total da
            operação.
            <br />
          </h4>
        </DualBox>
        <LineBottom />
      </WrapperDual>
      <WrapperDual color="#fff">
        <DualBox>
          <h3>Como é o modelo que você está acostumado?</h3>
          <h4>
            __ Demora para retorno do investimento. <br />
            __ Aumento e expansão depende somente do seu capital. <br />
            __ Crescimento limitado. <br />
            __ Pouca ou nenhuma experiência de mercado. <br />
            __ Dedicação em tempo integral. <br />
            __ Operação cara, ineficiência para sinistros. <br />
            __ Exposição ao risco de multas de trânsito. <br />
            __ Você por você mesmo. Informações e demonstrativos que não
            demonstram a operação real. <br />
          </h4>
        </DualBox>
      </WrapperDual>
    </Container>
  );
};

export default Page6;
